@import './variables.scss';

.nav-list{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .btn{
    border-radius: 22px;
    font-weight: bold;
    padding: 8px 24px;
  
    &:focus{
      box-shadow: 0 0 0 4px #fefefe3e;
    }
  }
  
  .default-px{
    padding: 0 10%;
  }
  
  .title{
    font-weight: bold;
  }

  .ilustration{
    max-width: 100%;
  }
  
  a{
    text-decoration: none;
  }

  h2{
    font-size: $sec-title-size;
    margin-bottom: 80px;
  }
  
  h3{
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  p{
    font-size: $body-font-size;
  }
  
  section{
    margin: 120px 0;
  }

  img{
    pointer-events: none;
  }

@media (max-width: $bp-01) {
  h2{
    font-size: 2.4rem;
    margin-bottom: 40px;
  }
  
  h3{
    font-size: 1.4rem;
    margin-bottom: 18px;
  }
  
  p, li{
    font-size: 1rem;
  }
  
}
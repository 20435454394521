@import '../../style/variables.scss';

#skills{
    background-color: $light-gray-bkg;
    overflow: hidden;

    &::before{
        content: " ";
        position: relative;
        top: -5px;
        background-image: url('../../assets/svg/others/wavy-contour.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 70px;
        width: 100%;
    }

    &::after{
        content: " ";
        position: relative;
        bottom: -5px;
        background-image: url('../../assets/svg/others/wavy-contour.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 70px;
        width: 100%;
        transform: rotate(180deg);
    }

    h2.title{
        margin-top: 56px;
    }

    .countainer{
        width: 100%;
        max-width: 1080px;

        img{
            margin-top: 24px;
            width: 480px;
        }
    }

    .countainer#back-end-skills{
        img{margin-left: 24px;}
    }

    .countainer#front-end-skills{
        margin-top: 120px;

        img{margin-right: 24px;}
    }

    .skill{
        max-width: 540px;
    }

    li{
        font-size: $body-font-size;
        margin-bottom: 16px;
        max-width: 100%;
    }

    #outher-skills{
        position: relative;
        width: 720px;
        max-width: 75%;
        margin: 80px 0;
        font-size: 18px;

        &::before{
            position: absolute;
            left: -36px;
            content: url('../../assets/svg/icons/arrow.svg');
        }
    }
}

@media (max-width: $bp-01) {

    #skills{

        h2.title{
            font-size: 2.4rem;
        }

        .countainer{
            max-width: 80%;
        }

        h3.title{
            font-size: 1.4rem;
        }

        p, li{
            font-size: 1rem;
        }
    }


}
@import '../../../style/variables.scss';

div.project-card{
    padding: 24px;
    width: 360px;
    max-width: 100%;
    border-radius: $border-radius;
    margin-bottom: 30px;

    .img-reference{
        width: 100%;
        height: 220px;
        padding: 24px;
        border-radius: $border-radius;
        background-color: $light-gray-bkg;
        margin-bottom: 30px;

        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    
    .btn{
        color: $light-tipografy;
        background-color: $deep-blue-bkg;
        width:100%;

        &:hover{
            opacity: .95;
        }
    }
    
}
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body{margin:0;font-family:"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fefefe}
.nav-list{list-style:none;margin:0;padding:0}.btn{border-radius:22px;font-weight:bold;padding:8px 24px}.btn:focus{box-shadow:0 0 0 4px #fefefe3e}.default-px{padding:0 10%}.title{font-weight:bold}.ilustration{max-width:100%}a{text-decoration:none}h2{font-size:4rem;margin-bottom:80px}h3{font-size:36px;margin-bottom:30px}p{font-size:1.1rem}section{margin:120px 0}img{pointer-events:none}@media(max-width: 1070px){h2{font-size:2.4rem;margin-bottom:40px}h3{font-size:1.4rem;margin-bottom:18px}p,li{font-size:1rem}}
#about-me h2.sec-title{color:#001524e6}#about-me p{width:800px;max-width:80%}#about-me img{max-width:80%}
#banner{position:relative;height:100vh;background-color:#2d4059;overflow:hidden}#banner #banner-content{width:1080px;max-width:80%}#banner #banner-content img#perfil{height:300px;width:300px;border-radius:100%;margin-right:32px}#banner #banner-content h1.title{color:#fefefe;font-size:4.5rem;font-weight:600;margin-bottom:18px;margin-top:-48px}#banner #banner-content h1.title span{font-size:1.2rem;font-weight:400}#banner #banner-content .btn#portfolio-button{color:#001524e6;background-color:rgba(254,254,254,.85);border:3px solid #fefefe;width:100%}#banner #banner-content .btn#portfolio-button:hover{background-color:#fefefe}#banner #waves{position:absolute;bottom:-3px;left:0px;width:100%}#banner #waves .wave{position:absolute;bottom:0;width:calc(100% + 80px);animation:wave-motion 3.5s ease-in-out alternate infinite both}#banner #waves .wave.w1{animation-delay:0s}#banner #waves .wave.w2{animation-delay:1s}#banner #waves .wave.w3{animation-delay:2s}#banner #waves .wave.w4{animation-delay:3s}@keyframes wave-motion{from{transform:translate(-80px)}}@media(max-width: 1070px){#banner #banner-content img#perfil{height:260px;width:260px;margin-right:12px}#banner #banner-content h1.title{font-size:3.5rem}#banner #banner-content a{width:100%}}@media(max-width: 700px){#banner #banner-content h1.title{font-size:2.5rem}}@media(max-width: 374px){#banner #banner-content img#perfil{height:200px;width:200px;margin-right:12px}}
#footer{background-color:#eee;padding:12px 5%}#footer #contact{padding:30px}#footer #contact span{font-weight:bold;font-size:18px}#footer #contact a{margin:12px 12px;color:#001524e6;font-weight:600}#footer #contact a.link-email:hover{text-decoration:underline}#footer #contact a.social-media:hover{opacity:.9}#footer #copyright{text-align:center;font-size:16px;font-weight:600;padding-top:16px;width:100%;border-top:1px solid #001524e6}
#header{position:absolute;width:100%;z-index:10}#header #logo:hover{opacity:.9}#header .btn#contact-button{color:#fefefe;border:2px solid #fefefe}#header .btn#contact-button:hover{background-color:#FEFEFE0F}#header .nav-list li a{font-weight:500;color:#fefefe;text-decoration:none;padding:12px 24px}#header .nav-list li a:hover{background-color:#FEFEFE0F;border-radius:22px}@media(max-width: 775px){#header{display:none !important}#banner #banner-content{position:relative;top:-18px}#banner #banner-content img#perfil{margin-bottom:48px}}@media(max-height: 645px){#header{display:none !important}#banner #banner-content{position:relative;top:-18px}#banner #banner-content img#perfil{margin-bottom:48px}}
#portfolio .countainer{width:100%}#portfolio ul{list-style:none;margin:0;padding:0}#portfolio ul li{max-width:100%}
div.project-card{padding:24px;width:360px;max-width:100%;border-radius:18px;margin-bottom:30px}div.project-card .img-reference{width:100%;height:220px;padding:24px;border-radius:18px;background-color:#eee;margin-bottom:30px}div.project-card .img-reference img{max-width:100%;max-height:100%}div.project-card .btn{color:#fefefe;background-color:#2d4059;width:100%}div.project-card .btn:hover{opacity:.95}
#skills{background-color:#eee;overflow:hidden}#skills::before{content:" ";position:relative;top:-5px;background-image:url(/static/media/wavy-contour.278cbcd3.svg);background-size:100%;background-repeat:no-repeat;height:70px;width:100%}#skills::after{content:" ";position:relative;bottom:-5px;background-image:url(/static/media/wavy-contour.278cbcd3.svg);background-size:100%;background-repeat:no-repeat;height:70px;width:100%;transform:rotate(180deg)}#skills h2.title{margin-top:56px}#skills .countainer{width:100%;max-width:1080px}#skills .countainer img{margin-top:24px;width:480px}#skills .countainer#back-end-skills img{margin-left:24px}#skills .countainer#front-end-skills{margin-top:120px}#skills .countainer#front-end-skills img{margin-right:24px}#skills .skill{max-width:540px}#skills li{font-size:1.1rem;margin-bottom:16px;max-width:100%}#skills #outher-skills{position:relative;width:720px;max-width:75%;margin:80px 0;font-size:18px}#skills #outher-skills::before{position:absolute;left:-36px;content:url(/static/media/arrow.70605786.svg)}@media(max-width: 1070px){#skills h2.title{font-size:2.4rem}#skills .countainer{max-width:80%}#skills h3.title{font-size:1.4rem}#skills p,#skills li{font-size:1rem}}
#toggle-drop-down-menu.btn{display:none;position:fixed;padding:10px;background-color:#526175;border-radius:8px;z-index:10;top:24px;right:24px}.dropdown-menu{right:24px;position:fixed;padding:16px}.dropdown-menu li a.dropdown-item{font-size:1.4rem;font-weight:500 !important;color:#001524e6 !important}.dropdown-menu.is-visible{display:block;animation:showDropDownMenu .5s ease-out both}@keyframes showDropDownMenu{from{top:-100%}to{top:84px}}@media(max-width: 775px){#toggle-drop-down-menu.btn{display:block}}@media(max-height: 645px){#toggle-drop-down-menu.btn{display:block}}

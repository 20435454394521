@import '../../style/variables.scss';

#banner{
    position: relative;
    height: 100vh;
    background-color: $deep-blue-bkg;
    overflow: hidden;

    #banner-content{
        width: 1080px;
        max-width: 80%;

        img#perfil{
            height: 300px;
            width: 300px;
            border-radius: 100%;
            margin-right: 32px;
        }

        h1.title{
            color: $light-tipografy;
            font-size: $main-title-size;
            font-weight: 600;
            margin-bottom: 18px;
            margin-top: -48px;

            span{
                font-size: 1.2rem;
                font-weight: 400;    
            }
        }

        .btn#portfolio-button{
            color: $deep-blue-tipografy;
            background-color: rgb(254, 254, 254, .85);
            border: 3px solid $light-tipografy;
            width: 100%;

            &:hover{
            background-color: $light-bkg;
            }
        }
    }

    #waves{
        position: absolute;
        bottom: -3px;
        left: 0px;
        width: 100%;

        .wave{
            position: absolute;
            bottom: 0;
            width: calc(100% + 80px);
            animation: wave-motion 3.5s ease-in-out alternate infinite both;
            
            &.w1{animation-delay: 0s;}
            &.w2{animation-delay: 1s;}
            &.w3{animation-delay: 2s;}
            &.w4{animation-delay: 3s;}
        }
    }
}

@keyframes wave-motion {
    from{
        transform: translate(-80px);
    }
}

@media (max-width: $bp-01) {
    #banner{
        #banner-content{
            img#perfil{
                height: 260px;
                width: 260px;
                margin-right: 12px;
            }
            
            h1.title{
                font-size: 3.5rem;
            }

            a{
                width: 100%;
            }
        }
    }
}

@media (max-width: $bp-03) {
    #banner{
        #banner-content{
            h1.title{
                font-size: 2.5rem;
            }
        }
    }
}

@media (max-width: $bp-04) {
    #banner{
        #banner-content{
            img#perfil{
                height: 200px;
                width: 200px;
                margin-right: 12px;
            }
        }
    }
}
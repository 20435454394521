@import '../../style/variables.scss';

#header{
    position: absolute;
    width: 100%;
    z-index: 10;

    #logo:hover{
        opacity: .9;
    }

    .btn#contact-button{
        color: $light-tipografy;
        border: 2px solid $light-tipografy;

        &:hover{
            background-color: #FEFEFE0F;
        }
    }

    .nav-list li{
        
        a{
            font-weight: 500;
            color: $light-tipografy;
            text-decoration: none;
            padding: 12px 24px;
            
            &:hover{
                background-color: #FEFEFE0F;
                border-radius: 22px;
            }
        }
    }
}

@media (max-width: $bp-02 ) {
    #header{
        display: none!important;
    }

    #banner #banner-content{
        position: relative;
        top: -18px;

        img#perfil{
            margin-bottom: 48px;
        }
    }

}


@media (max-height: 645px){
    
    #header{
        display: none!important;
    }

    #banner #banner-content{
        position: relative;
        top: -18px;

        img#perfil{
            margin-bottom: 48px;
        }
    }

}
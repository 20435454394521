/* Tipografy */
// Color
$light-tipografy: #FEFEFE;
$deep-blue-tipografy:  #001524e6;
// size
$main-title-size: 4.5rem;
$sec-title-size: 4rem;
$body-font-size: 1.1rem;

/* ------------------------------- */
/* Fill */
$light-bkg: #FEFEFE;
$deep-blue-bkg: #2D4059;
$light-gray-bkg: #EEEEEE;

/* ------------------------------- */
/* Structure */
$border-radius: 18px;

/* ------------------------------- */
/* Brack Points */
$bp-01: 1070px;
$bp-02: 775px;
$bp-03: 700px;
$bp-04: 374px;
@import '../../style/variables.scss';

#about-me{

    h2.sec-title{
        color: $deep-blue-tipografy;
    }

    p{
        width: 800px;
        max-width: 80%;
    }

    img{
        max-width: 80%;
    }
}
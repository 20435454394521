@import '../../style/variables.scss';

#portfolio{

    .countainer{
        width: 100%
    }

    ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            max-width: 100%;
        }
    }
}